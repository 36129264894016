import { useState } from 'react';
import emailjs from 'emailjs-com';

export function Backform() {

  const [name, setName] = useState(
    window.sessionStorage.getItem('name') || ""
  );
  const [email, setEmail] = useState(
    window.sessionStorage.getItem('email') || ""
  );
  const [message, setMessage] = useState(
    window.sessionStorage.getItem('message') || ""
  );
  const [status, setStatus] = useState(0);
  const [cnt, setCnt] = useState(0);

  function setNameH(e) {
    window.sessionStorage.setItem('name', e.target.value);
    setName(e.target.value);
  }

  function setEmailH(e) {
    window.sessionStorage.setItem('email', e.target.value);
    setEmail(e.target.value);
  }

  function setMessageH(e) {
    window.sessionStorage.setItem('message', e.target.value);
    setMessage(e.target.value);
  }

  function sendIt(e) {
    if (status === 4) {
      return;
    }
    if (message === "" || name === "" || email === "") {
      setStatus(2);
      setCnt(cnt + 1);
      return;
    }
    setStatus(4);
    let data = {
      'email': escape(email),
      'name': escape(name),
      'message': escape(message),
    }
    emailjs.send(
      'service_xa0sh4r',
      'template_vh5t6if',
      data,
      'user_hxrckJkcSIJDXPkHItMtG'
    )
      .then((result) => {
        console.log(result);
        setMessage("");
        setName("");
        setEmail("");
        window.sessionStorage.setItem('name', "");
        window.sessionStorage.setItem('email', "");
        window.sessionStorage.setItem('message', "");
        setStatus(3);
      }, (error) => {
        console.log(error.text);
        setStatus(1);
      });
  }


  let oopsShow = status === 1 ? "" : "hidden";
  let fieldsShow1 = status === 2 && cnt % 2 === 0 ? "" : "hidden";
  let fieldsShow2 = status === 2 && cnt % 2 === 1 ? "" : "hidden";
  let successShow = status === 3 ? "" : "hidden";
  let spinShow = status === 4 ? "" : "hidden";
  let wholeBox = status === 0 ? "hidden" : "";

  return (<section className="relative block py-24 lg:pt-0 bg-gray-900">
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
        <div className="w-full lg:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300">
            <div className="flex-auto p-5 lg:p-10">
              <h4 className="text-2xl font-semibold">
                Want to work with me?
              </h4>
              <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                Complete this form and I will get back.
              </p>
              <div className={"flex " + wholeBox}>
                <p className={"text-red-500 font-bold " + oopsShow}>
                  Ooops, something went wrong, try again later.
                </p>
                <p className={"text-red-500 font-bold highlight-it " + fieldsShow1}>
                  All fields must be filled.
                </p>
                <p className={"text-red-500 font-bold highlight-it " + fieldsShow2}>
                  All fields must be filled.
                </p>
                <p className={"text-green-600 font-bold " + successShow}>
                  Success!
                </p>
                <p className={"" + spinShow}>
                  Sending... &nbsp; <i class="fas fa-circle-notch fa-spin"></i>
                </p>
                <div className flex="flex-1"/>
              </div>
              <div className="relative w-full mb-3 mt-8">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="full-name"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                  placeholder="Full Name"
                  style={{ transition: "all .15s ease" }}
                  onChange={setNameH}
                  value={name}
                />
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                  placeholder="Email"
                  style={{ transition: "all .15s ease" }}
                  onChange={setEmailH}
                  value={email}
                />
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  rows="4"
                  cols="80"
                  name="message"
                  className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                  placeholder="Type a message..."
                  onChange={setMessageH}
                  value={message}
                />
              </div>
              <div className="text-center mt-6">
                <button
                  className="bg-gray-800 hover:bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                  onClick={sendIt}
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>);
}
